import React from 'react'
import CaseStudiesSidebar from './CaseStudiesSidebar'
import details1 from '../../assets/images/projects/projects-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'

const CaseStudiesDetailsContent = (props) => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={props.banner_img} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">{props.type}</span>
                            <h3>{props.title}</h3>
                            <p>{props.description}</p>
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            {props.facts.map((fact,index) =>(
                                                <li key={index}><span>{fact.label}</span></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <p>{props.long_description1}</p>
                            <p>{props.long_description2}</p>
                            <h3>Results</h3>
                            <p>{props.results}</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar 
                            client={props.client}
                            location={props.location}
                            technologies={props.technologies}
                            completed={props.completed}
                            website={props.website}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CaseStudiesDetailsContent