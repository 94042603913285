import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from '../components/CaseStudiesDetails/CaseStudiesDetailsContent'
import RelatedProjects from '../components/CaseStudiesDetails/RelatedProjects'
import banner_img from '../assets/images/projects/project2.jpg'

const facts = [
    {
        id: 0,
        className: 'postgresql',
        label: 'Athleisure',
    },
    {
        id: 1,
        className: 'elasticsearch',
        label: 'Living on the go!',
    },
    {
        id: 2,
        className: 'opensearch',
        label: 'Active Lifestyle',
    },
    {
        id: 3,
        className: 'opensearch',
        label: 'Proudly South African',
    },
];

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Case Studies Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Case Studies Details" 
            />
            <CaseStudiesDetailsContent
                banner_img={banner_img}
                facts={facts}
                client={"active360™"}
                location={"Johanessburg, South Africa"}
                technologies={"React, Typescript, MongoDB, Paystack"}
                completed={"May 2024"}
                website={"https://active360.co.za"}
                type={"eCommerce Athleisure"}
                title={"active360™"}
                description={"active360™ is the activewear brand designed for every body on-the-go!"}
                important_facts={""}
                long_description1={"Established in 2022 , active360™ is a dynamic brand that burst onto the scene in 2022, driven by a vision to revolutionise the activewear industry. With a firm commitment to offering exceptional value and style, active360™ has quickly become synonymous with top-notch quality and affordability. What sets us apart is our unwavering dedication to promoting proudly African styles, incorporating vibrant prints, bold colors, and traditional motifs into our designs."}
                long_description2={"Living on the go? Stay stylish, stay active with active360™, the brand that keeps up with your fast-paced lifestyle."}
                results={"The project is ongoing and will reach beta 2024."}
            />
            {/* <RelatedProjects /> */}
            <Footer />
        </Layout>
    );
}

export default CaseDetails